
const initialState = {
    city: "Nagpur"
}


const Reducer = (state = initialState, action)=>{
    switch (action.type){
        case "SetCity" :
            return {
                ...state,
                city: action.payload.city
            };
            default:
                return state;
    }
}


export default Reducer;