import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBBA5iHXupY7XWlVvF8Xm-Zc0HCC-Lv1d8",
    authDomain: "mfs-live.firebaseapp.com",
    projectId: "mfs-live",
    storageBucket: "mfs-live.appspot.com",
    messagingSenderId: "805155199510",
    appId: "1:805155199510:web:037c8a73ea4e0379883337",
    measurementId: "G-E7J1RPXRGK"
  };

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const vapidKey = "BOMXaFnuSAFHyqQQkPZbgRncHU6Vf4C-hHuv_Sln2_sX8JC0t3bXfbB5DoYzzQ8rxYk0YLpkhWIfWQjZuGyTbDM"

export const requestFCMToken = async () => {
    console.log("requestFCMToken called from app.js")
    if (!("Notification" in window)) {
      throw new Error("This browser does not support notifications.");
    }
    console.log("Notification.requestPermission() called :", Notification.requestPermission())
    return Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          return getToken(messaging, { vapidKey });
        } else {
          throw new Error("Notification permission not granted.");
        }
      })
      .catch((err) => {
        console.error("Error requesting FCM token:", err);
        throw err;
      });
  };

export const onMessageListener = ()=>{
  return  new Promise((resolve)=>{
        onMessage(messaging, (payload)=>{
            resolve(payload)
        } )
    })
}